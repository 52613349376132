export const performance = 'performance'

// [export]
export const performance_export = 'performance.export'

// [account]
export const performance_account_description_edit =
  'performance.account.description.edit'
// [account]

// [create]
export const performance_context_create = 'performance.context.create'
export const performance_objective_create = 'performance.objective.create'
export const performance_keyResult_create = 'performance.kr.create'
export const performance_initiative_create = 'performance.initiatives.create'

export const performance_objective_strategic_create =
  'performance.objective-strategic.create'
export const performance_keyResult_strategic_create =
  'performance.kr-strategic.create'
export const performance_objective_tatical_create =
  'performance.objective-tatical.create'
export const performance_keyResult_tatical_create =
  'performance.kr-tatical.create'
export const performance_objective_operational_create =
  'performance.objective-operational.create'
export const performance_keyResult_operational_create =
  'performance.kr-operational.create'
export const performance_objective_operationalBreakdown_create =
  'performance.objective-operational-breakdown.create'
export const performance_keyResult_operationalBreakdown_create =
  'performance.kr-operational-breakdown.create'
// [create]

// [delete]
export const performance_context_delete = 'performance.context.delete'
export const performance_objective_delete = 'performance.objective.delete'
export const performance_keyResult_delete = 'performance.kr.delete'
export const performance_initiative_delete = 'performance.initiatives.delete'

export const performance_objective_strategic_delete =
  'performance.objective-strategic.delete'
export const performance_keyResult_strategic_delete =
  'performance.kr-strategic.delete'
export const performance_objective_tatical_delete =
  'performance.objective-tatical.delete'
export const performance_keyResult_tatical_delete =
  'performance.kr-tatical.delete'
export const performance_objective_operational_delete =
  'performance.objective-operational.delete'
export const performance_keyResult_operational_delete =
  'performance.kr-operational.delete'
export const performance_objective_operationalBreakdown_delete =
  'performance.objective-operational-breakdown.delete'
export const performance_keyResult_operationalBreakdown_delete =
  'performance.kr-operational-breakdown.delete'

// [delete]

// [edit]
export const performance_context_edit = 'performance.context.edit'
export const performance_objective_edit = 'performance.objective.edit'
export const performance_keyResult_edit = 'performance.kr.edit'
export const performance_initiative_edit = 'performance.initiatives.edit'
export const performance_initiative_edit_back_prioritization =
  'performance.initiatives.edit.back-prioritization'

export const performance_objective_strategic_edit =
  'performance.objective-strategic.edit'
export const performance_keyResult_strategic_edit =
  'performance.kr-strategic.edit'
export const performance_objective_tatical_edit =
  'performance.objective-tatical.edit'
export const performance_keyResult_tatical_edit = 'performance.kr-tatical.edit'
export const performance_objective_operational_edit =
  'performance.objective-operational.edit'
export const performance_keyResult_operational_edit =
  'performance.kr-operational.edit'
export const performance_objective_operationalBreakdown_edit =
  'performance.objective-operational-breakdown.edit'
export const performance_keyResult_operationalBreakdown_edit =
  'performance.kr-operational-breakdown.edit'
// [edit]

// [move]
export const performance_context_move = 'performance.context.move'
export const performance_objective_move = 'performance.objective.move'
export const performance_keyResult_move = 'performance.kr.move'
export const performance_initiative_move = 'performance.initiatives.move'

export const performance_objective_strategic_move =
  'performance.objective-strategic.move'
export const performance_keyResult_strategic_move =
  'performance.kr-strategic.move'
export const performance_objective_tatical_move =
  'performance.objective-tatical.move'
export const performance_keyResult_tatical_move = 'performance.kr-tatical.move'
export const performance_objective_operational_move =
  'performance.objective-operational.move'
export const performance_keyResult_operational_move =
  'performance.kr-operational.move'
export const performance_objective_operationalBreakdown_move =
  'performance.objective-operational-breakdown.move'
export const performance_keyResult_operationalBreakdown_move =
  'performance.kr-operational-breakdown.move'
// [move]

export const performance_context_move_cycle = 'performance.context.move.cycle'
export const performance_objective_move_cycle =
  'performance.objective.move.cycle'
export const performance_keyResult_move_cycle = 'performance.kr.move.cycle'
export const performance_initiative_move_cycle =
  'performance.initiatives.move.cycle'

//[move cycle]

// [duplicate]
export const performance_context_duplicate = 'performance.context.duplicate'
export const performance_objective_duplicate = 'performance.objective.duplicate'
export const performance_keyResult_duplicate = 'performance.kr.duplicate'
export const performance_initiative_duplicate =
  'performance.initiatives.duplicate'

export const performance_objective_strategic_duplicate =
  'performance.objective-strategic.duplicate'
export const performance_keyResult_strategic_duplicate =
  'performance.kr-strategic.duplicate'
export const performance_objective_tatical_duplicate =
  'performance.objective-tatical.duplicate'
export const performance_keyResult_tatical_duplicate =
  'performance.kr-tatical.duplicate'
export const performance_objective_operational_duplicate =
  'performance.objective-operational.duplicate'
export const performance_keyResult_operational_duplicate =
  'performance.kr-operational.duplicate'
export const performance_objective_operationalBreakdown_duplicate =
  'performance.objective-operational-breakdown.duplicate'
export const performance_keyResult_operationalBreakdown_duplicate =
  'performance.kr-operational-breakdown.duplicate'
// [duplicate]

/** sections */
// [title]
export const performance_context_edit_title = 'performance.context.edit.title'
export const performance_objective_edit_title =
  'performance.objective.edit.title'
export const performance_keyResult_edit_title = 'performance.kr.edit.title'
export const performance_initiative_edit_title =
  'performance.initiatives.edit.title'

export const performance_objective_strategic_edit_title =
  'performance.objective-strategic.edit.title'
export const performance_keyResult_strategic_edit_title =
  'performance.kr-strategic.edit.title'
export const performance_objective_tatical_edit_title =
  'performance.objective-tatical.edit.title'
export const performance_keyResult_tatical_edit_title =
  'performance.kr-tatical.edit.title'
export const performance_objective_operational_edit_title =
  'performance.objective-operational.edit.title'
export const performance_keyResult_operational_edit_title =
  'performance.kr-operational.edit.title'
export const performance_objective_operationalBreakdown_edit_title =
  'performance.objective-operational-breakdown.edit.title'
export const performance_keyResult_operationalBreakdown_edit_title =
  'performance.kr-operational-breakdown.edit.title'
// [title]

// [description]
export const performance_objective_edit_description =
  'performance.objective.edit.description'
export const performance_keyResult_edit_description =
  'performance.kr.edit.description'
export const performance_initiative_edit_description =
  'performance.initiatives.edit.description'

export const performance_objective_strategic_edit_description =
  'performance.objective-strategic.edit.description'
export const performance_keyResult_strategic_edit_description =
  'performance.kr-strategic.edit.description'
export const performance_objective_tatical_edit_description =
  'performance.objective-tatical.edit.description'
export const performance_keyResult_tatical_edit_description =
  'performance.kr-tatical.edit.description'
export const performance_objective_operational_edit_description =
  'performance.objective-operational.edit.description'
export const performance_keyResult_operational_edit_description =
  'performance.kr-operational.edit.description'
export const performance_objective_operationalBreakdown_edit_description =
  'performance.objective-operational-breakdown.edit.description'
export const performance_keyResult_operationalBreakdown_edit_description =
  'performance.kr-operational-breakdown.edit.description'
// [description]

// [owners]
export const performance_context_edit_owners = 'performance.context.edit.owners'
export const performance_objective_edit_owners =
  'performance.objective.edit.owners'
export const performance_keyResult_edit_owners = 'performance.kr.edit.owners'
export const performance_initiative_edit_owners =
  'performance.initiatives.edit.owners'

export const performance_objective_strategic_edit_owners =
  'performance.objective-strategic.edit.owners'
export const performance_keyResult_strategic_edit_owners =
  'performance.kr-strategic.edit.owners'
export const performance_objective_tatical_edit_owners =
  'performance.objective-tatical.edit.owners'
export const performance_keyResult_tatical_edit_owners =
  'performance.kr-tatical.edit.owners'
export const performance_objective_operational_edit_owners =
  'performance.objective-operational.edit.owners'
export const performance_keyResult_operational_edit_owners =
  'performance.kr-operational.edit.owners'
export const performance_objective_operationalBreakdown_edit_owners =
  'performance.objective-operational-breakdown.edit.owners'
export const performance_keyResult_operationalBreakdown_edit_owners =
  'performance.kr-operational-breakdown.edit.owners'
// [owners]

// [cycle]
export const performance_context_edit_cycle = 'performance.context.edit.cycle'
export const performance_objective_edit_cycle =
  'performance.objective.edit.cycle'
export const performance_keyResult_edit_cycle = 'performance.kr.edit.cycle'
export const performance_initiative_edit_cycle =
  'performance.initiatives.edit.cycle'
export const performance_initiative_create_cycle =
  'performance.initiatives.create.cycle'

// [group]
export const performance_initiative_edit_group =
  'performance.initiatives.edit.group'
export const performance_initiative_create_group =
  'performance.initiatives.create.group'

export const performance_objective_strategic_edit_cycle =
  'performance.objective-strategic.edit.cycle'
export const performance_keyResult_strategic_edit_cycle =
  'performance.kr-strategic.edit.cycle'
export const performance_objective_tatical_edit_cycle =
  'performance.objective-tatical.edit.cycle'
export const performance_keyResult_tatical_edit_cycle =
  'performance.kr-tatical.edit.cycle'
export const performance_objective_operational_edit_cycle =
  'performance.objective-operational.edit.cycle'
export const performance_keyResult_operational_edit_cycle =
  'performance.kr-operational.edit.cycle'
export const performance_objective_operationalBreakdown_edit_cycle =
  'performance.objective-operational-breakdown.edit.cycle'
export const performance_keyResult_operationalBreakdown_edit_cycle =
  'performance.kr-operational-breakdown.edit.cycle'
// [cycle]

// [workflowStatus]
export const performance_objective_edit_workflowStatus =
  'performance.objective.edit.workflowStatus'
export const performance_keyResult_edit_workflowStatus =
  'performance.kr.edit.workflowStatus'
export const performance_initiative_edit_workflowStatus =
  'performance.initiatives.edit.workflowStatus'

export const performance_initiative_edit_status =
  'performance.initiatives.edit.status'

export const performance_initiative_edit_resendinvitation =
  'performance.initiatives.edit.resendinvitation'

export const performance_objective_strategic_edit_workflowStatus =
  'performance.objective-strategic.edit.workflowStatus'
export const performance_keyResult_strategic_edit_workflowStatus =
  'performance.kr-strategic.edit.workflowStatus'
export const performance_objective_tatical_edit_workflowStatus =
  'performance.objective-tatical.edit.workflowStatus'
export const performance_keyResult_tatical_edit_workflowStatus =
  'performance.kr-tatical.edit.workflowStatus'
export const performance_objective_operational_edit_workflowStatus =
  'performance.objective-operational.edit.workflowStatus'
export const performance_keyResult_operational_edit_workflowStatus =
  'performance.kr-operational.edit.workflowStatus'
export const performance_objective_operationalBreakdown_edit_workflowStatus =
  'performance.objective-operational-breakdown.edit.workflowStatus'
export const performance_keyResult_operationalBreakdown_edit_workflowStatus =
  'performance.kr-operational-breakdown.edit.workflowStatus'
// [workflowStatus]

// [private]
export const performance_context_edit_private =
  'performance.context.edit.private'
export const performance_objective_edit_private =
  'performance.objective.edit.private'
export const performance_keyResult_edit_private = 'performance.kr.edit.private'
export const performance_initiative_edit_private =
  'performance.initiatives.edit.private'

export const performance_objective_strategic_edit_private =
  'performance.objective-strategic.edit.private'
export const performance_keyResult_strategic_edit_private =
  'performance.kr-strategic.edit.private'
export const performance_objective_tatical_edit_private =
  'performance.objective-tatical.edit.private'
export const performance_keyResult_tatical_edit_private =
  'performance.kr-tatical.edit.private'
export const performance_objective_operational_edit_private =
  'performance.objective-operational.edit.private'
export const performance_keyResult_operational_edit_private =
  'performance.kr-operational.edit.private'
export const performance_objective_operationalBreakdown_edit_private =
  'performance.objective-operational-breakdown.edit.private'
export const performance_keyResult_operationalBreakdown_edit_private =
  'performance.kr-operational-breakdown.edit.private'
// [private]

// [tags]
export const performance_context_edit_tags = 'performance.context.edit.tags'
export const performance_objective_edit_tags = 'performance.objective.edit.tags'
export const performance_keyResult_edit_tags = 'performance.kr.edit.tags'
export const performance_initiative_edit_tags =
  'performance.initiatives.edit.tags'

export const performance_objective_strategic_edit_tags =
  'performance.objective-strategic.edit.tags'
export const performance_keyResult_strategic_edit_tags =
  'performance.kr-strategic.edit.tags'
export const performance_objective_tatical_edit_tags =
  'performance.objective-tatical.edit.tags'
export const performance_keyResult_tatical_edit_tags =
  'performance.kr-tatical.edit.tags'
export const performance_objective_operational_edit_tags =
  'performance.objective-operational.edit.tags'
export const performance_keyResult_operational_edit_tags =
  'performance.kr-operational.edit.tags'
export const performance_objective_operationalBreakdown_edit_tags =
  'performance.objective-operational-breakdown.edit.tags'
export const performance_keyResult_operationalBreakdown_edit_tags =
  'performance.kr-operational-breakdown.edit.tags'
// [tags]

// [prioritization]
export const performance_context_edit_prioritization =
  'performance.context.edit.prioritization'
export const performance_objective_edit_prioritization =
  'performance.objective.edit.prioritization'
export const performance_keyResult_edit_prioritization =
  'performance.kr.edit.prioritization'
export const performance_initiative_edit_prioritization =
  'performance.initiatives.edit.prioritization'

export const performance_objective_strategic_edit_prioritization =
  'performance.objective-strategic.edit.prioritization'
export const performance_keyResult_strategic_edit_prioritization =
  'performance.kr-strategic.edit.prioritization'
export const performance_objective_tatical_edit_prioritization =
  'performance.objective-tatical.edit.prioritization'
export const performance_keyResult_tatical_edit_prioritization =
  'performance.kr-tatical.edit.prioritization'
export const performance_objective_operational_edit_prioritization =
  'performance.objective-operational.edit.prioritization'
export const performance_keyResult_operational_edit_prioritization =
  'performance.kr-operational.edit.prioritization'
export const performance_objective_operationalBreakdown_edit_prioritization =
  'performance.objective-operational-breakdown.edit.prioritization'
export const performance_keyResult_operationalBreakdown_edit_prioritization =
  'performance.kr-operational-breakdown.edit.prioritization'
// [prioritization]

// [people]
export const performance_context_edit_people = 'performance.context.edit.people'
export const performance_objective_edit_people =
  'performance.objective.edit.people'
export const performance_keyResult_edit_people = 'performance.kr.edit.people'
export const performance_initiative_edit_people =
  'performance.initiatives.edit.people'

export const performance_objective_strategic_edit_people =
  'performance.objective-strategic.edit.people'
export const performance_keyResult_strategic_edit_people =
  'performance.kr-strategic.edit.people'
export const performance_objective_tatical_edit_people =
  'performance.objective-tatical.edit.people'
export const performance_keyResult_tatical_edit_people =
  'performance.kr-tatical.edit.people'
export const performance_objective_operational_edit_people =
  'performance.objective-operational.edit.people'
export const performance_keyResult_operational_edit_people =
  'performance.kr-operational.edit.people'
export const performance_objective_operationalBreakdown_edit_people =
  'performance.objective-operational-breakdown.edit.people'
export const performance_keyResult_operationalBreakdown_edit_people =
  'performance.kr-operational-breakdown.edit.people'
// [people]

// [people-export]
export const performance_context_edit_peopleExport =
  'performance.context.edit.people-export'
export const performance_objective_edit_peopleExport =
  'performance.objective.edit.people-export'
export const performance_keyResult_edit_peopleExport =
  'performance.kr.edit.people-export'
export const performance_initiative_edit_peopleExport =
  'performance.initiatives.edit.people-export'

export const performance_objective_strategic_edit_peopleExport =
  'performance.objective-strategic.edit.people-export'
export const performance_keyResult_strategic_edit_peopleExport =
  'performance.kr-strategic.edit.people-export'
export const performance_objective_tatical_edit_peopleExport =
  'performance.objective-tatical.edit.people-export'
export const performance_keyResult_tatical_edit_peopleExport =
  'performance.kr-tatical.edit.people-export'
export const performance_objective_operational_edit_peopleExport =
  'performance.objective-operational.edit.people-export'
export const performance_keyResult_operational_edit_peopleExport =
  'performance.kr-operational.edit.people-export'
export const performance_objective_operationalBreakdown_edit_peopleExport =
  'performance.objective-operational-breakdown.edit.people-export'
export const performance_keyResult_operationalBreakdown_edit_peopleExport =
  'performance.kr-operational-breakdown.edit.people-export'
// [people-export]

// [metadata]
export const performance_objective_edit_metadata =
  'performance.objective.edit.metadata'
export const performance_keyResult_edit_metadata =
  'performance.kr.edit.metadata'
export const performance_initiative_edit_metadata =
  'performance.initiatives.edit.metadata'

export const performance_objective_strategic_edit_metadata =
  'performance.objective-strategic.edit.metadata'
export const performance_keyResult_strategic_edit_metadata =
  'performance.kr-strategic.edit.metadata'
export const performance_objective_tatical_edit_metadata =
  'performance.objective-tatical.edit.metadata'
export const performance_keyResult_tatical_edit_metadata =
  'performance.kr-tatical.edit.metadata'
export const performance_objective_operational_edit_metadata =
  'performance.objective-operational.edit.metadata'
export const performance_keyResult_operational_edit_metadata =
  'performance.kr-operational.edit.metadata'
export const performance_objective_operationalBreakdown_edit_metadata =
  'performance.objective-operational-breakdown.edit.metadata'
export const performance_keyResult_operationalBreakdown_edit_metadata =
  'performance.kr-operational-breakdown.edit.metadata'
// [metadata]

// [series]
export const performance_keyResult_edit_series = 'performance.kr.edit.series'
export const performance_keyResult_edit_seriesPlannedOldest =
  'performance.kr.edit.series-planned.oldest'
export const performance_keyResult_edit_seriesPlanned =
  'performance.kr.edit.series-planned'
export const performance_keyResult_edit_seriesAccomplished =
  'performance.kr.edit.series-accomplished'
export const performance_keyResult_edit_seriesCustom =
  'performance.kr.edit.series-custom'

export const performance_keyResult_strategic_edit_series =
  'performance.kr-strategic.edit.series'
export const performance_keyResult_strategic_edit_seriesPlanned =
  'performance.kr-strategic.edit.series-planned'
export const performance_keyResult_strategic_edit_seriesAccomplished =
  'performance.kr-strategic.edit.series-accomplished'
export const performance_keyResult_strategic_edit_seriesCustom =
  'performance.kr-strategic.edit.series-custom'

export const performance_keyResult_tatical_edit_series =
  'performance.kr-tatical.edit.series'
export const performance_keyResult_tatical_edit_seriesPlanned =
  'performance.kr-tatical.edit.series-planned'
export const performance_keyResult_tatical_edit_seriesAccomplished =
  'performance.kr-tatical.edit.series-accomplished'
export const performance_keyResult_tatical_edit_seriesCustom =
  'performance.kr-tatical.edit.series-custom'

export const performance_keyResult_operational_edit_series =
  'performance.kr-operational.edit.series'
export const performance_keyResult_operational_edit_seriesPlanned =
  'performance.kr-operational.edit.series-planned'
export const performance_keyResult_operational_edit_seriesAccomplished =
  'performance.kr-operational.edit.series-accomplished'
export const performance_keyResult_operational_edit_seriesCustom =
  'performance.kr-operational.edit.series-custom'
export const performance_keyResult_operationalBreakdown_edit_seriesCustom =
  'performance.kr-operational-breakdown.edit.series-custom'
// [series]

// [measurementModel]
export const performance_keyResult_edit_measurementModel =
  'performance.kr.edit.measurementModel'

export const performance_keyResult_strategic_edit_measurementModel =
  'performance.kr-strategic.edit.measurementModel'
export const performance_keyResult_tatical_edit_measurementModel =
  'performance.kr-tatical.edit.measurementModel'
export const performance_keyResult_operational_edit_measurementModel =
  'performance.kr-operational.edit.measurementModel'
export const performance_keyResult_operationalBreakdownm_edit_measurementModel =
  'performance.kr-operational-breakdown.edit.measurementModel'
// [measurementModel]

// [workflow initiatives actions]

export const performance_initiative_edit_prioritive =
  'performance.initiatives.edit.prioritize'
export const performance_initiative_edit_deprioritize =
  'performance.initiatives.edit.deprioritize'
export const performance_initiative_edit_cancel =
  'performance.initiatives.edit.cancel'
export const performance_initiative_edit_complete =
  'performance.initiatives.edit.complete'

// [coresponsability initiatives actions]

export const performance_initiative_edit_accepted =
  'performance.initiatives.edit.accepted'
export const performance_initiative_edit_rejected =
  'performance.initiatives.edit.rejected'
export const performance_initiative_edit_corresponsibleRemove =
  'performance.initiatives.edit.corresponsible.remove'
export const performance_initiative_edit_corresponsibleAdd =
  'performance.initiatives.edit.corresponsible.add'

// [actionPlan key-result actions]

export const performance_keyResult_create_actionPlan =
  'performance.kr.create.actionPlan'
export const performance_keyResult_strategic_create_actionPlan =
  'performance.kr-strategic.create.actionPlan'
export const performance_keyResult_tatical_create_actionPlan =
  'performance.kr-tatical.create.actionPlan'

export const performance_keyResult_tatical_edit_actionPlan =
  'performance.kr-tatical.edit.actionPlan'
export const performance_keyResult_strategic_edit_actionPlan =
  'performance.kr-strategic.edit.actionPlan'
export const performance_keyResult_edit_actionPlan =
  'performance.kr.edit.actionPlan'

// [entities]
export const performance_entities_follow_up = 'performance.entities.follow-up'
export const performance_entities_hide_create =
  'performance.entities.hide-create'
export const performance_entities_deleted = 'performance.entities.deleted'
// [highlight key-result actions]

export const performance_keyResult_create_highlight =
  'performance.kr.create.highlight'
export const performance_keyResult_strategic_create_highlight =
  'performance.kr-strategic.create.highlight'
export const performance_keyResult_tatical_create_highlight =
  'performance.kr-tatical.create.highlight'

export const performance_keyResult_tatical_edit_highlight =
  'performance.kr-tatical.edit.highlight'
export const performance_keyResult_strategic_edit_highlight =
  'performance.kr-strategic.edit.highlight'
export const performance_keyResult_edit_highlight =
  'performance.kr.edit.highlight'

// [performance dashboard]
export const performance_dashboards = 'performance.dashboards'
export const performance_dashboards_overview = 'performance.dashboards.overview'
export const performance_dashboards_managementReports =
  'performance.dashboards.management-reports'

// [performance actionplan ]
export const performance_actionPlan = 'performance.kr.actionPlan'
export const performance_keyResult_tatical_actionPlan =
  'performance.kr-tatical.actionPlan'
export const performance_keyResult_strategic_actionPlan =
  'performance.kr-strategic.actionPlan'

export const performance_context_restore = 'performance.context.restore'
export const performance_objective_restore = 'performance.objective.restore'
export const performance_keyResult_restore = 'performance.kr.restore'
export const performance_initiative_restore = 'performance.initiatives.restore'

//[performance columnsHandler]
export const performance_entities_customize_columns =
  'performance.entities.customize-columns'

export const performance_subIndicator = 'performance.sub-indicator'
export const performance_subIndicator_strategic =
  'performance.sub-indicator-strategic'
export const performance_subIndicator_strategic_actionPlan =
  'performance.sub-indicator-strategic.actionPlan'
export const performance_subIndicator_strategic_create =
  'performance.sub-indicator-strategic.create'
export const performance_subIndicator_strategic_create_actionPlan =
  'performance.sub-indicator-strategic.create.actionPlan'
export const performance_subIndicator_strategic_create_highlight =
  'performance.sub-indicator-strategic.create.highlight'
export const performance_subIndicator_strategic_delete =
  'performance.sub-indicator-strategic.delete'
export const performance_subIndicator_strategic_duplicate =
  'performance.sub-indicator-strategic.duplicate'
export const performance_subIndicator_strategic_edit =
  'performance.sub-indicator-strategic.edit'
export const performance_subIndicator_strategic_edit_actionPlan =
  'performance.sub-indicator-strategic.edit.actionPlan'
export const performance_subIndicator_strategic_edit_cycle =
  'performance.sub-indicator-strategic.edit.cycle'
export const performance_subIndicator_strategic_edit_description =
  'performance.sub-indicator-strategic.edit.description'
export const performance_subIndicator_strategic_edit_highlight =
  'performance.sub-indicator-strategic.edit.highlight'
export const performance_subIndicator_strategic_edit_measurementModel =
  'performance.sub-indicator-strategic.edit.measurementModel'
export const performance_subIndicator_strategic_edit_metadata =
  'performance.sub-indicator-strategic.edit.metadata'
export const performance_subIndicator_strategic_edit_owners =
  'performance.sub-indicator-strategic.edit.owners'
export const performance_subIndicator_strategic_edit_people =
  'performance.sub-indicator-strategic.edit.people'
export const performance_subIndicator_strategic_edit_peopleExport =
  'performance.sub-indicator-strategic.edit.people-export'
export const performance_subIndicator_strategic_edit_prioritization =
  'performance.sub-indicator-strategic.edit.prioritization'
export const performance_subIndicator_strategic_edit_private =
  'performance.sub-indicator-strategic.edit.private'
export const performance_subIndicator_strategic_edit_series =
  'performance.sub-indicator-strategic.edit.series'
export const performance_subIndicator_strategic_edit_seriesAccomplished =
  'performance.sub-indicator-strategic.edit.series-accomplished'
export const performance_subIndicator_strategic_edit_seriesCustom =
  'performance.sub-indicator-strategic.edit.series-custom'
export const performance_subIndicator_strategic_edit_seriesPlanned =
  'performance.sub-indicator-strategic.edit.series-planned'
export const performance_subIndicator_strategic_edit_tags =
  'performance.sub-indicator-strategic.edit.tags'
export const performance_subIndicator_strategic_edit_title =
  'performance.sub-indicator-strategic.edit.title'
export const performance_subIndicator_strategic_edit_workflowStatus =
  'performance.sub-indicator-strategic.edit.workflowStatus'
export const performance_subIndicator_strategic_move =
  'performance.sub-indicator-strategic.move'
export const performance_subIndicator_tatical =
  'performance.sub-indicator-tatical'
export const performance_subIndicator_tatical_actionPlan =
  'performance.sub-indicator-tatical.actionPlan'
export const performance_subIndicator_tatical_create =
  'performance.sub-indicator-tatical.create'
export const performance_subIndicator_tatical_create_actionPlan =
  'performance.sub-indicator-tatical.create.actionPlan'
export const performance_subIndicator_tatical_delete =
  'performance.sub-indicator-tatical.delete'
export const performance_subIndicator_tatical_duplicate =
  'performance.sub-indicator-tatical.duplicate'
export const performance_subIndicator_tatical_edit =
  'performance.sub-indicator-tatical.edit'
export const performance_subIndicator_tatical_edit_actionPlan =
  'performance.sub-indicator-tatical.edit.actionPlan'
export const performance_subIndicator_tatical_edit_cycle =
  'performance.sub-indicator-tatical.edit.cycle'
export const performance_subIndicator_tatical_edit_description =
  'performance.sub-indicator-tatical.edit.description'
export const performance_subIndicator_tatical_edit_measurementModel =
  'performance.sub-indicator-tatical.edit.measurementModel'
export const performance_subIndicator_tatical_edit_metadata =
  'performance.sub-indicator-tatical.edit.metadata'
export const performance_subIndicator_tatical_edit_owners =
  'performance.sub-indicator-tatical.edit.owners'
export const performance_subIndicator_tatical_edit_people =
  'performance.sub-indicator-tatical.edit.people'
export const performance_subIndicator_tatical_edit_peopleExport =
  'performance.sub-indicator-tatical.edit.people-export'
export const performance_subIndicator_tatical_edit_prioritization =
  'performance.sub-indicator-tatical.edit.prioritization'
export const performance_subIndicator_tatical_edit_private =
  'performance.sub-indicator-tatical.edit.private'
export const performance_subIndicator_tatical_edit_series =
  'performance.sub-indicator-tatical.edit.series'
export const performance_subIndicator_tatical_edit_seriesAccomplished =
  'performance.sub-indicator-tatical.edit.series-accomplished'
export const performance_subIndicator_tatical_edit_seriesCustom =
  'performance.sub-indicator-tatical.edit.series-custom'
export const performance_subIndicator_tatical_edit_seriesPlanned =
  'performance.sub-indicator-tatical.edit.series-planned'
export const performance_subIndicator_tatical_edit_tags =
  'performance.sub-indicator-tatical.edit.tags'
export const performance_subIndicator_tatical_edit_title =
  'performance.sub-indicator-tatical.edit.title'
export const performance_subIndicator_tatical_edit_workflowStatus =
  'performance.sub-indicator-tatical.edit.workflowStatus'
export const performance_subIndicator_tatical_move =
  'performance.sub-indicator-tatical.move'
export const performance_subIndicator_actionPlan =
  'performance.sub-indicator.actionPlan'
export const performance_subIndicator_create =
  'performance.sub-indicator.create'
export const performance_subIndicator_create_actionPlan =
  'performance.sub-indicator.create.actionPlan'
export const performance_subIndicator_delete =
  'performance.sub-indicator.delete'
export const performance_subIndicator_duplicate =
  'performance.sub-indicator.duplicate'
export const performance_subIndicator_edit = 'performance.sub-indicator.edit'
export const performance_subIndicator_edit_actionPlan =
  'performance.sub-indicator.edit.actionPlan'
export const performance_subIndicator_edit_cycle =
  'performance.sub-indicator.edit.cycle'
export const performance_subIndicator_edit_description =
  'performance.sub-indicator.edit.description'
export const performance_subIndicator_edit_measurementModel =
  'performance.sub-indicator.edit.measurementModel'
export const performance_subIndicator_edit_metadata =
  'performance.sub-indicator.edit.metadata'
export const performance_subIndicator_edit_owners =
  'performance.sub-indicator.edit.owners'
export const performance_subIndicator_edit_people =
  'performance.sub-indicator.edit.people'
export const performance_subIndicator_edit_peopleExport =
  'performance.sub-indicator.edit.people-export'
export const performance_subIndicator_edit_prioritization =
  'performance.sub-indicator.edit.prioritization'
export const performance_subIndicator_edit_private =
  'performance.sub-indicator.edit.private'
export const performance_subIndicator_edit_series =
  'performance.sub-indicator.edit.series'
export const performance_subIndicator_edit_seriesAccomplished =
  'performance.sub-indicator.edit.series-accomplished'
export const performance_subIndicator_edit_seriesCustom =
  'performance.sub-indicator.edit.series-custom'
export const performance_subIndicator_edit_seriesPlanned =
  'performance.sub-indicator.edit.series-planned'
export const performance_subIndicator_edit_tags =
  'performance.sub-indicator.edit.tags'
export const performance_subIndicator_edit_title =
  'performance.sub-indicator.edit.title'
export const performance_subIndicator_edit_workflowStatus =
  'performance.sub-indicator.edit.workflowStatus'
export const performance_subIndicator_move = 'performance.sub-indicator.move'
export const performance_subIndicator_move_cycle =
  'performance.sub-indicator.move.cycle'
export const performance_subIndicator_restore =
  'performance.sub-indicator.restore'
export const performance_subIndicator_section =
  'performance.kr.sub-indicator-section'

export const performance_kpi = 'performance.kpi'
export const performance_kpi_create = 'performance.kpi.create'
export const performance_kpi_edit = 'performance.kpi.edit'
export const performance_kpi_edit_title = 'performance.kpi.edit.title'
export const performance_kpi_edit_owners = 'performance.kpi.edit.owners'
export const performance_kpi_edit_description =
  'performance.kpi.edit.description'
export const performance_kpi_edit_cycle = 'performance.kpi.edit.cycle'
export const performance_kpi_edit_workflowStatus =
  'performance.kpi.edit.workflowStatus'
export const performance_kpi_edit_private = 'performance.kpi.edit.private'
export const performance_kpi_edit_tags = 'performance.kpi.edit.tags'
export const performance_kpi_edit_prioritization =
  'performance.kpi.edit.prioritization'
export const performance_kpi_edit_people = 'performance.kpi.edit.people'
export const performance_kpi_edit_peopleExport =
  'performance.kpi.edit.people-export'
export const performance_kpi_edit_metadata = 'performance.kpi.edit.metadata'
export const performance_kpi_edit_metadataPermissioned =
  'performance.kpi.edit.medatada-permissioned'
export const performance_kpi_edit_series = 'performance.kpi.edit.series'
export const performance_kpi_edit_seriesPlanned =
  'performance.kpi.edit.series-planned'
export const performance_kpi_edit_seriesAccomplished =
  'performance.kpi.edit.series-accomplished'
export const performance_kpi_edit_seriesCustom =
  'performance.kpi.edit.series-custom'
export const performance_kpi_edit_measurementModel =
  'performance.kpi.edit.measurementModel'
export const performance_kpi_actionPlan = 'performance.kpi.actionPlan'
export const performance_kpi_create_actionPlan =
  'performance.kpi.create.actionPlan'
export const performance_kpi_edit_actionPlan = 'performance.kpi.edit.actionPlan'
export const performance_kpi_delete = 'performance.kpi.delete'
export const performance_kpi_move = 'performance.kpi.move'
export const performance_kpi_move_cycle = 'performance.kpi.move.cycle'
export const performance_kpi_duplicate = 'performance.kpi.duplicate'
export const performance_kpi_restore = 'performance.kpi.restore'

// Goals tabs permissions
export const performance_goals = 'performance.goals'
export const performance_goals_dashboards_me = 'performance.goals.dashboards.me'
export const performance_goals_dashboards = 'performance.goals.dashboards'
export const performance_goals_dashboards_comparison =
  'performance.goals.dashboards.comparison'
export const performance_goals_dashboards_comparison_edit =
  'performance.goals.dashboards.comparison.edit'
