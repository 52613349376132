import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import { UserModule } from './modules/user'

/**
 * @type {import('vuex').Store}
 */
export default new Vuex.Store({
  modules: {
    currentUser: UserModule,
  },

  state: {
    Page: {
      isSignedIn: false,
    },

    groupItens: [],
    cyclesItens: [],
    hierarchySettings: [],

    activeCycles: [],

    workflowColors: {},
    workflowActions: [],
    selectedGroups: [],
    selectedPeople: [],
    selectedOrigin: 'people',
    redirectedPerson: {},

    performanceFullScreen: false,
    treeMode: '',
    referenceMonth: null,
    progressType: 'early-to-date',
  },

  getters: {
    isSignedIn: ({ Page }) => Page.isSignedIn,

    groupItens: state => state.groupItens,
    cyclesItens: state => state.cyclesItens,
    hierarchySettings: state => state.hierarchySettings,

    activeCycles: state => state.activeCycles,

    workflowColors: state => state.workflowColors,
    workflowActions: state => state.workflowActions,

    performanceFullScreen: state => state.performanceFullScreen,
    treeMode: state => state.treeMode,
    referenceMonth: state => state.referenceMonth,
    progressType: state => state.progressType,

    // EntitySelector
    currentEntity: ({ selectedGroups, selectedPeople, selectedOrigin }) => {
      if (selectedOrigin === 'groups') {
        return selectedGroups
      }
      return selectedPeople
    },

    currentEntityOrigin: ({ selectedOrigin }) => selectedOrigin,
  },
  mutations: {
    SET_page_isSignedIn(state, payload) {
      state.Page.isSignedIn = payload
    },

    setGroupItens(state, value) {
      state.groupItens = value
    },
    setCyclesItens(state, value) {
      state.cyclesItens = value
    },
    setHierarchySettings(state, value) {
      state.hierarchySettings = value
    },

    setActiveCycles(state, value) {
      state.activeCycles = value
    },

    setWorkflowColors(state, value) {
      state.workflowColors = value
    },
    setWorkflowActions(state, value) {
      state.workflowActions = value
    },

    setPerformanceFullScreen(state, value) {
      state.performanceFullScreen = value
    },
    setTreeMode(state, value) {
      state.treeMode = value
    },
    setReferenceMonth(state, value) {
      state.referenceMonth = value
    },

    setProgressType(state, value) {
      state.progressType = value
    },

    // EntitySelector
    SET_SELECTED_GROUPS(state, payload) {
      state.selectedGroups = payload
    },
    SET_SELECTED_PEOPLE(state, payload) {
      state.selectedPeople = payload
    },
    SET_SELECTED_ORIGIN(state, payload) {
      state.selectedOrigin = payload
    },

    set_redirected_goals_search(state, payload) {
      state.redirectedPerson = payload
    },
  },
  actions: {
    signedInLogin({ commit }) {
      commit('SET_page_isSignedIn', true)
    },
    signedInLogout({ commit }) {
      commit('SET_page_isSignedIn', false)
    },

    setGroupItens({ commit }, value) {
      commit('setGroupItens', value)
    },
    setCyclesItens({ commit }, value) {
      commit('setCyclesItens', value)
    },
    setHierarchySettings({ commit }, value) {
      commit('setHierarchySettings', value)
    },

    setActiveCycles({ commit }, value) {
      commit('setActiveCycles', value)
    },

    setWorkflowColors({ commit }, value) {
      commit('setWorkflowColors', value)
    },
    setWorkflowActions({ commit }, value) {
      commit('setWorkflowActions', value)
    },

    setPerformanceFullScreen({ commit }, value) {
      commit('setPerformanceFullScreen', value)
    },
    setTreeMode({ commit }, value) {
      commit('setTreeMode', value)
    },
    setReferenceMonth({ commit }, value) {
      commit('setReferenceMonth', value)
    },
    setProgressType({ commit }, value) {
      commit('setProgressType', value)
    },

    // EntitySelector
    setSelectedGroups({ commit }, payload) {
      commit('SET_SELECTED_GROUPS', payload)
    },
    setSelectedPeople({ commit }, payload) {
      commit('SET_SELECTED_PEOPLE', payload)
    },
    setSelectedOrigin({ commit }, payload) {
      commit('SET_SELECTED_ORIGIN', payload)
    },
  },
})
